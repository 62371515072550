"use client";

import "../../../index.css";
import { customTwMerge } from "../../../utils/customTwMerge";
import { ButtonProps } from "./Button.type";
import {
  BUTTON_APPEARANCE_SELECTED_STYLE_MAP,
  createButtonSizeStyle,
  BUTTON_APPEARANCE_DEFAULT_STYLE_MAP,
  BUTTON_APPEARANCE_DISABLED_STYLE_MAP,
  BUTTON_APPEARANCE_HOVER_STYLE_MAP,
  BUTTON_APPEARANCE_PRESSED_STYLE_MAP,
} from "./Button.style";

export const Button = ({
  children,
  icon,
  size,
  appearance = "default",
  isSelected,
  isDisabled,
  disabled,
  className,
  ...restProps
}: ButtonProps) => {
  const isButtonDisabled = isDisabled || disabled;

  return (
    <button
      className={customTwMerge(
        BUTTON_APPEARANCE_DISABLED_STYLE_MAP[appearance],
        createButtonSizeStyle({ size, hasIcon: !!icon }),
        !disabled &&
          !isSelected &&
          BUTTON_APPEARANCE_PRESSED_STYLE_MAP[appearance],
        !disabled &&
          !isSelected &&
          BUTTON_APPEARANCE_HOVER_STYLE_MAP[appearance],
        isSelected && BUTTON_APPEARANCE_SELECTED_STYLE_MAP[appearance],
        !isSelected && BUTTON_APPEARANCE_DEFAULT_STYLE_MAP[appearance],
        icon && "group flex items-center gap-[4px]",
        className,
      )}
      type="button"
      disabled={isButtonDisabled}
      {...restProps}
    >
      {icon && icon}
      {children}
    </button>
  );
};
